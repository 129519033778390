import React from "react";
import { FaInstagram, FaMediumM } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Icons() {
  return (
    <div className="bg-white p-10">
      <div className=" max-w-7xl flex">
        <FaInstagram className="bg-green-default text-lg rounded-full text-white p-2 mr-3 w-14 h-14" />
        <MdEmail className="bg-green-default text-lg rounded-full text-white p-2 mr-3 w-14 h-14" />
        <FaMediumM className="bg-green-default text-lg rounded-full text-white p-2 mr-3 w-14 h-14" />
      </div>
    </div>
  );
}

export default Icons;
