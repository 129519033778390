import React from "react";

function Faq() {
  return (
    <div className=" bg-green-default md:pb-20 pb-10">
      <div className="section-container">
        <div className="flex w-full justify-center">
          <h1 className="heading">FAQ</h1>
        </div>
        <div className="bg-white px-6 py-5 md:mt-20 mt-10">
          <div className="grid  md:grid-cols-2 grid-cols-1 md:mt-16 mt-8  gap-10">
            {DataFaqs.map((DataFaq) => {
              return (
                <div className="bg-white py-5 border_gray">
                  <div className="flex  flex-col   w-full">
                    <div className="border_gray_bottom ">
                      <h1 className="font-bold pb-4 px-4 text-black-default text-lg ">
                        {DataFaq.title}
                      </h1>
                    </div>
                    <div className="p-7 ">
                      <p className=" text-lg">{DataFaq.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
const DataFaqs = [
  {
    id: 1,
    title: "DO BIOBLINDS WORK WITH ALL PLANTS?",
    desc: "Bioblinds work in different climates with different tendril plants. It is important that the plants fulfill certain characteristics – which also depend on the climate zone. In Germany eg. Bioblinds work very well with ivy and hops. In Italy, rather with jasmine. It is important that they are tendril plants. In addition, the connection between the necessary earth / soil and the length of the Bioblinds must be taken into account. Other plants and their needs shall be tested in the Research Cube.",
  },
  {
    id: 2,
    title: "CAN YOU ATTACH BIOBLINDS AS A MODULE TO ANY KIND OF ARCHITECTURE?",
    desc: "Basically, that’s possible. However, if necessary, one needs approvals of the owner and, perhaps, any kind of building authorities. As far as the exact construction measures and mechanics are concerned, we want to generate detailed plans and technical drawings including shopping lists with exact product names in the envisioned Research Cube. We see this entire vision as a stimulation to think in kinetic vertical gardens.",
  },
  {
    id: 3,
    title:
      "DO I HAVE TO BE FRIENDS WITH THE PEOPLE WHO LIVE ABOVE ME, SO THAT THEY WATER MY BIOBLINDS?",
    desc: "It is definitely helpful to know your vertical neighbours. Bioblinds connect people. It is important that the water supply is ensured. With modern systems from Gardena or Regenmeister.com this is absolutely no problem – without even knowing your vertical neighbour. There are also possibilities for very cost-efficient solutions with special attachments on bottles or sound-suction mechanisms. We see this entire vision as a stimulation to think in kinetic vertical gardens.",
  },
  {
    id: 4,
    title: "DO BIOBLINDS WORK WITHOUT WEIGHTS?",
    desc: "The wind loads make the weights or a corresponding substitute, as in the original Bioblinds, necessary. But to make Bioblinds yourself, weights make things a whole lot easier. It is important that you pay attention to the rubber border, otherwise damage to the glass can be taken. Although also the mechanism makes sure everything is safe and sound. In the Research Cube various mechanisms, in particular substitutes, will be tested.",
  },
  {
    id: 5,
    title: "DOES THE PROTOTYPE IV WORK?",
    desc: "Yes! The prototype IV works perfectly. Even leaving it open for a few days and nights does not hurt the plants at all. Due to the special mechanism, the inner part of the plant is turned outwards. This leads to an unchanged growth rate along the steel cables. In the pictures you will find a diagram. There you can see how well the plants grow along the ropes downwards. This natural mechanism is examined in more detail in the Research Cube. Oh, and by the way, all prototypes worked!",
  },
  {
    id: 6,
    title: "DO I HAVE TO CONSTANTLY “CLIP” THE PLANTS ON THE STEEL CABLES?",
    desc: "According to the previous tests, a “structuring” of the Bioblinds on the steel cables helps – e.g. every week. However, it also depends on the plant and other factors. At the moment, we suspect that a horizontal convergence would even have positive influences (such as not needing the horizontal connection any more). Here, the Research Cube will bring many new insights that are likely to vary from plant to plant. We see this entire vision as a stimulation to think in kinetic vertical gardens.",
  },
  {
    id: 7,
    title: "IS IT ONLY POSSIBLE TO OPEN AND CLOSE BIOBLINDS FROM THE OUTSIDE?",
    desc: "No, basically not. Even permanently installed shutters are not closed from the outside. The liana can be easily guided inwards and even be electronically operated. But also these details are to be researched exactly in the Research Cube. We see this entire vision as a stimulation to think in kinetic vertical gardens.",
  },
  {
    id: 8,
    title: "WHAT EXACTLY DOES OPEN-SOURCE-DIY-TOOLKIT MEAN?",
    desc: "DIY means Do-It-Yourself. We want the Bioblinds-idea to release as much oxygen as possible in as many cities worldwide as possible. As a result, we will offer all our ideas, detailed knowledge, plant specifications, technical building instructions and mechanic-details as free data online for everyone. We even want every person who has built his own Bioblinds to enrich this data with his experience and knowledge. The DIY toolkit is to be developed in the Research Cube.",
  },
  {
    id: 9,
    title: "WHAT DOES ALL THIS HAVE TO DO WITH THE GERMAN UNITY?",
    desc: "Bioblinds embody a sustainable social curtain that connects people and plants from different cultures. The prototype stands for a transcultural interaction space. Open-source and DIY are democratic digital uses. Bioblinds make interior and exterior architecture more personal and more open. All of this is also an expression of democratic freedom for us. Oh, and since Tino comes from the UK, we are somehow also “against the Brexit”.",
  },
  {
    id: 10,
    title: "DO BIOBLINDS SURVIVE IN THE WINTER?",
    desc: "It depends absolutely on the plants. To be on the safe side, you should design the flower boxes in the modular system with Styrofoam. In the integrated system, the flower boxes are virtually in the walls and are automatically protected. An important point in very cold climates, however, is that then the steel cables must be sheathed or be made of a different material, otherwise they could increase the cold. But we want to find out all that in the Research Cube!",
  },
  {
    id: 11,
    title: "HOW MUCH OXYGEN IS PRODUCED BY BIOBLINDS?",
    desc: "It depends a lot on the plant, the photosynthesis and the area. Hints provide different studies from different countries. The impact can definitely be greater than with all other known measures, since theoretically all surfaces can be used. But it’s not just about oxygen. An important advantage of Bioblinds is the possible cooling within living spaces. This is further enhanced by the external attachment. And we are not even talking about the psychological effects.",
  },
  {
    id: 12,
    title: "IS THAT GOOD FOR THE PLANTS? DON’T THEY HAVE TO GROW UPWARDS?",
    desc: "ContThat’s a question we’ve spent years looking at and which has cost the most time and money in the research. Because plants should be free in nature. With our solution, we offer a functional extension to move plants without hurting them, thus closing the gaps of vertical gardening. According to our discussions with experts, this does not harm the plant in any way. Of course, that can be discussed at different levels. We also want to further enhance this side of the mechanism in the research cube.ent 6",
  },
  {
    id: 13,
    title:
      "HOW CAN YOU PRESERVE THE QUALITY OF THE EARTH / SOIL IN THE LONG TERM?",
    desc: "We also thought about that a lot. For one thing, there are different irrigation / watering options that can be used to simultaneously feed nutrition. On the other hand, it shall be possible to “re-pot” the Bioblinds, if necessary. This also is ensured by the mechanism.",
  },
  {
    id: 14,
    title:
      "IF I BUILD MY OWN BIOBLINDS, HOW LONG WILL IT TAKE BEFORE I CAN USE THEM?",
    desc: "It depends on the growth rate and the original size of the plant. You can grow Bioblinds over a period of several years from an early age or buy large plants. In our experiments we have documented an average growth rate of 4cm per week. We also love the speculative design of the mechanism and would even use that without plants and then look at how they grow down along the steel cables.",
  },
  {
    id: 15,
    title:
      "HOW CAN THE BIOBLINDS WORK IN STRONG WINDS? ARE ANY WINDOWS AT RISK?",
    desc: "Due to the unique mechanism, it is possible to stabilize the Bioblinds by weights even in strong winds. The weights are protected by rubber and do not endangered the facade. Due to the mechanics, the weights are never close to the glass. As if that were not enough security, we want to elaborate a “ski-pass mechanism” in the research cube, which can keep the steel fixed yet flexible without weights.",
  },
  {
    id: 16,
    title: "HOW ARE THE STEEL CABLES FASTENED? ARE THEY REALLY STABLE?",
    desc: "The steel cables are fastened to the “flower box” above the window or door with hardware from a handystores. The attachment as such is bombproof. However, the attachment on the “flower box” and the width of the steel cables, in particular the traction cables, must meet certain requirements. The distances between the individual steel cables are important here as well as the mechanism.",
  },
  {
    id: 17,
    title: "TO WHAT EXTENT IS THE CONCEPT REALLY WORKING?",
    desc: "We have built several prototypes that all work. The basic mechanics have been found and will now be adapted in the next step in relation to different plants and climates, architectures and city plannings. For this we plan the research cube or something similiar. We want to use this idea and the first solutions to stimulate greener cities. We do not claim to have the perfect solution, we rather want to invite everybody to participate in this opensource vision, which just needs your starting help.",
  },
  {
    id: 18,
    title:
      "WHICH PLANTS DO YOU STILL SEE AS USABLE IN ADDITION TO CONVENTIONAL TENDRIL PLANTS?",
    desc: "For years, we thought we needed to genetically modify a plant. Fortunately, this is not the case, as discussions with specialists have shown. The mechanism works with selected tendrils and hanging plants. Of course, the choice depends on the personal preferences, but also on the climatic and architectural conditions. So far, we have made experiments with hops, ivy, wisteria, jasmine, wild wine, dichondra, indian mint, incense and kiwi.",
  },
  {
    id: 19,
    title:
      "DO YOU WORK WITH BIOLOGISTS / LANDSCAPE PLANNERS / ARCHITECTS / CIVIL ENGINEERS & SCIENTISTS? IS THE PROJECT SCIENTIFICALLY BASED?",
    desc: "We have had quite a few discussions with biologists, landscape planners, gardeners, representatives of conservation organizations, architects and civil engineers. In addition, we ourselves have carried out scientific experiments aimed at a) not injuring the plants, b) ensuring water supply and most importantly c) finding the mechanics. In addition, we carry out with each prototype further series of experiments. However, the research cube should generate the final scientific basis.",
  },
  {
    id: 20,
    title: "ARE YOU USING EXISTING RESEARCH?",
    desc: "Jan had the dream when he celebrated his 17th birthday in NYC – in a ground floor house overlooking garbage cans. Since then, he has been thinking about how to make urban prospects and view from apartments for low-end housing more attractive. Many attempts in the last 20 years have failed – because we have tried to build on existing research. At the beginning of 2019, we were finally able to identify the basic mechanics within the team and make the prototypes work flawlessly.",
  },
  {
    id: 21,
    title:
      "WHAT DO YOU MEAN BY VERTICAL GARDENS 2.0? WHAT SETS THESE GARDENS APART FROM CONVENTIONAL GREEN FACADES?",
    desc: "Here, the kinetic or mobile effect is very important to us. Especially today, when we have a lot of glass in architecture. That means to us: Surfaces that could be green are kind of “lost.” With our mechanics, vertical gardening can now take place not only on static facades but also in front of windows and doors. Because we found a fully functional approach to simply open and close the organic curtains. Of course, in a more elaborated future version this will be electronically possible as well.",
  },
  {
    id: 22,
    title: "WHO CARES FOR THE GARDENS AND HOW?",
    desc: "It is a mechanism that allows everyone to make their piece of skyline green. In the DIY version, the vertical gardens are maintained by the homeowners – on the ground floor from the outside or inside, from the first floor on out of the windows. If you wanted to cover a skyscraper with Bioblinds, you would have to fall back on care specialists, similar to the cleaning of glass facades. We do not claim to have the perfect solution: We want to stipulate the idea and invite everybody to contribute.",
  },
  {
    id: 23,
    title: "HOW DO YOU INTEND TO PROTECT THE HOUSE FACADES?",
    desc: "The protection of the house facades strongly depends on the respective plant. For ivy, for example, it is important to use a spacer, which is part of the basic mechanics, to let the plants “hang” at a distance of 10-20 cm from the house. According to different biologists and gardeners it needs to made sure (with ivy exclusively) that it does not constantly touches the wall of the house. To be honest, we like to think that Bioblinds rather protect the house facades from the weather!",
  },
  {
    id: 24,
    title:
      "TO WHAT EXTENT WILL THE “CURTAINS” BE MOVABLE – WHICH SYSTEM SHOULD BE BEHIND IT?",
    desc: "Yes, and how can you make the plant curtains movable without injuring or overstressing the plants? For 3 months only this years we stood on ladders and refined the basic mechanics with all its components on the current prototype. The answer results from a triad of a spacer, different spatial levels within the mechanics and the ratio of the height roots, the attachment at the towing steel rope and, obviously, the size of the window / door. Our research shows that even tiny details are crucial.",
  },
  {
    id: 25,
    title:
      "WHY DON’T YOU GUYS MAKE A PATENT OUT OF THIS AND EARN A HELL LOT OF MONEY?",
    desc: "This is actually the question we get mostly. Our answer is quite simple: We want as many people as possible to able to apply our knowledge. The moment we made the idea and mechanism public, we prohibited other e.g. companies to patent the technique. We dont want to earn money with the idea. And we also dont want big companies to earn money with it. We just want to create a common vision that can be done by everybody.",
  },
  {
    id: 26,
    title: "WHAT MEANS DIY?",
    desc: "DIY means Do-It-Yourself. We want provide the data that every person on this earth can build their own Bioblinds. This is simply what DIY means.",
  },
  {
    id: 27,
    title:
      "WHY DO YOU GUYS SOMETIMES USE THIS KIND OF “OLD” GRAPHIC DESIGN IN YOUR COMMUNICATION?",
    desc: "To be honest, we think that everything can be made shiny these days. This project is about a hands-on “real” thing and therefore we do not want to waste any money for videomakers oder glossy films. With this Web 1.0 feeling we also want to focus on the content and the actual human interaction in this project as a speculative design approach for a more sustainable future for everybody with one common vision and our environment as a common commons.",
  },
  {
    id: 28,
    title:
      "WHY SHOULD I FUND THIS PROJECT, WHEN I CAN ACCESS ALL INFORMATION ANYWAY AND THEN I HAVE TO EVEN BUILD IT MYSELF?",
    desc: "This project only wants to make people think in the direction of verticals gardens as possibilities for windows and doors. We do not claim to have the best solution for each climate zone. This is why we want to activate this project as a commons movement where nearly everybody could participate. Another reason why we make all our research public this is to prohibit commercial companies from claiming a patent on KINETIC vertical gardens and do not offer it in a democratic way.",
  },
];
