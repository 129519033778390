import React from "react";
import Video from "./Video";
import Cards from "./Cards";
import Faq from "./Faq";
import Icons from "./Icons";
import Layout from "./../Layout/Layout";

const Index = () => {
  return (
    <Layout>
      <Video />
      <Cards />
      <Faq />
    </Layout>
  );
};

export default Index;
