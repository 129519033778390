import React from "react";
import {Link} from "gatsby";
import Img1 from "./../../images/diyeditions6/2.png";
import Img2 from "./../../images/diyeditions6/Bildschirmfoto-1.jpg";
import Img3 from "./../../images/diyeditions6/picto.png";
import Img4 from "./../../images/diyeditions6/1.png";

function Cards() {
  return (
    <div className=" bg-green-default md:pb-20 pb-10">
      <div className="section-container">
        <div className="flex w-full justify-center">
          <h1 className="heading">DIY-editions</h1>
        </div>
        <div className="bg-white px-6 py-5 md:mt-20 mt-10">
          <p className="para-graph ">
            Collaborate on impulses for instruction plans for DIY-plant-curtains
            and bring us closer to our utopian goal to create a ‚Bioblinds
            Configurator‘. Our goal is to collect enough data and create an
            elaborated information system (the ‘Bioblinds Configurator’) that
            allows users to ‚scan‘ every window or door with an AR-Bioblinds-App
            and automatically get individual plant recommendations, specific
            construction plans and shopping lists. So far, we work on two
            editions.
          </p>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:mt-16 mt-8  gap-10">
          {DataCards.map((DataCard) => {
            return (
              <div className="bg-white px-4 py-5">
                <div className="flex  flex-col items-center justify-between w-full">
                  <div className="w-full flex justify-center">
                    {" "}
                    <img src={DataCard.img} alt="" className=" w-48" />
                  </div>
                  <div className="pt-8 text-center">
                    <Link
                      to={DataCard.url}
                      className="boxes-links font-bold text-black-default" target={DataCard.target}
                    >
                      {DataCard.title}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Cards;
const DataCards = [
  {
    id: 1,
    title: "BIOBLINDS 1ST EDITION IVY",
    img: Img1,
    url: "/first-edition",
    target:"",
  },
  {
    id: 2,
    title: "BIOBLINDS 2ND EDITION COWSHED",
    img: Img2,
    url: "/bioblinds-2nd-edition-cowshed",
    target:"",
  },
  {
    id: 3,
    title: "BIOBLINDS PLANT RESEARCH HUB",
    img: Img3,
    url: "/plant-research-hub",
    target:"",
  },
  {
    id: 4,
    title: "SUPPORT OUR CROWDFUNDING",
    img: Img4,
    url: "https://www.indiegogo.com/projects/bioblinds-the-green-skyline-initiative",
    target:"__blank",
  },
];
